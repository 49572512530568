import React, { useEffect, useState, useContext } from 'react';
import * as colors from "../assets/css/Colors";

const Userprofileimage = ({ text }) => {
      // Start color change auto function
      const [colorScheme, setColorScheme] = useState(
        window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
      );
      useEffect(() => {
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e) => {
          setColorScheme(e.matches ? 'dark' : 'light');
        };
        mediaQuery.addEventListener('change', handleChange);
        return () => {
          mediaQuery.removeEventListener('change', handleChange);
        };
      }, []);
    // End color change auto function
  return (
    <img
      src={`https://dodigitals.in/indiancitizencard/public/${text}`}
      alt='DoDigital'
      loading="lazy"
      style={{border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}`, }}
    />
  );
};

export default Userprofileimage;
