import React, { useEffect, useState, useRef, useContext } from "react";
import './citizencard.css'
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import * as colors from "../assets/css/Colors";
import Imagegif from "../components/Image_gif";

import Darkimg from '../assets/img/curve2.png';
import whiteimg from '../assets/img/curve1.png';

import Darkbg from '../assets/img/bg-1.webp';
import whitebg from '../assets/img/bg-1-light.webp';

// language
import { LanguageContext } from '../detectlanguage/LanguageContext';
import translate from "../detectlanguage/translate";

// Darkimg and whiteimg card
import darkdesktopimg1 from '../assets/img/do-digital-card/Do-Digital-Orange-Desktop-1.webp';
import darkdesktopimg2 from '../assets/img/do-digital-card/Do-DIgital-Orange-Desktop-2.webp';
import darkmobileimg1 from '../assets/img/do-digital-card/Do-Digital-Orange-Mobile-1.webp';
import darkmobileimg2 from '../assets/img/do-digital-card/Do-Digital-Orange-Mobile-2.webp';
import whitedesktopimg1 from '../assets/img/do-digital-card/Do-Digital-Green-Desktop-1.webp';
import whitedesktopimg2 from '../assets/img/do-digital-card/Do-Digital-Green-Desktop-2.webp';
import whitemobileimg1 from '../assets/img/do-digital-card/Do-Digital-Green-Mobile-1.webp';
import whitemobileimg2 from '../assets/img/do-digital-card/Do-Digital-Green-Mobile-2.webp';

// api
import { getStateList, getDistrictList, createUser } from '../components/Api';

// Loaction 
import { getGeocodeFromCoordinates, getGeocodeFromPincode } from '../components/googleMapsAPI';

// Loader
import Loader from '../components/Loader';

// profile image
import Profileimage from '../assets/img/profile-image.webp'
// 
import { PDFDocument } from 'pdf-lib';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import 'pdfjs-dist/web/pdf_viewer.css';
import jsPDF from 'jspdf';

// qr code
import QRCode from 'qrcode.react';
import CryptoJS from 'crypto-js';
// import crypto from 'crypto';

import Darkcardimg from '../assets/img/server/do-gif-orange.gif';
import whitecardimg from '../assets/img/server/do-gif-green.gif';

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;


const Citizencard = () => {

  // image card dark and light mode 
  const darkdesktopimg1URL = darkdesktopimg1;
  const darkdesktopimg2URL = darkdesktopimg2;
  const darkmobileimg1URL = darkmobileimg1;
  const darkmobileimg2URL = darkmobileimg2;
  const whitedesktopimg1URL = whitedesktopimg1;
  const whitedesktopimg2URL = whitedesktopimg2;
  const whitemobileimg1URL = whitemobileimg1;
  const whitemobileimg2URL = whitemobileimg2;


  const lightcardImageURL = whitecardimg;
  const darkcardImageURL = Darkcardimg;



  // erroe massage open popup 

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageInfo, setMessageInfo] = useState({ message: [], type: '' });
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [showPdfPopup, setShowPdfPopup] = useState(false);
  const [pdfMessage, setPdfMessage] = useState('');
  const [view, setView] = useState('vertical-front');
  const [rtoNumbers, setRtoNumbers] = useState([]);
  const fileInputRef = useRef(null);


  const handleShow = (message, type = 'error', show = true) => {
    setMessageInfo({ message, type });
    setShow(show);
    if (type === 'success') {
      setTimeout(() => {
        setShow(false);
        setShowSecondPopup(true);
      }, 5000); // Auto close after 5 seconds
    }
  };


  const handlePdfPopup = (message, type = 'success') => {
    setMessageInfo({ message: [message], type });
    setShow(true);
    setShowPdfPopup(true);
    setTimeout(() => {
      setShow(false);
      setShowPdfPopup(false);
    }, 3000); // Auto close after 5 seconds
  };


  // api call function start
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const stateData = await getStateList();
        setStates(stateData.data);
      } catch (error) {
        // console.error('Error fetching states:', error);
      }
    };

    fetchData();
  }, []);

  const handleStateChange = async (stateId) => {
    try {
      const districtData = await getDistrictList(stateId);
      setDistricts(districtData.data);
    } catch (error) {
      // console.error('Error fetching districts:', error);
    }
  };

  // creatuser api call data
  const [location, setLocation] = useState({ lat: null, lng: null, name: '' }); // Location function start
  const [dodigital_id, setDodigital_id] = useState('');
  const [user_fname, setUserfname] = useState('');
  const [user_lname, setUserlname] = useState('');
  const [user_image, setUserimage] = useState();
  const [user_id, setUserid] = useState();
  const [staticUrl, setStaticUrl] = useState('');

  const [userData, setUserData] = useState({
    username:'',
    fname: '',
    lname: '',
    email: '',
    mobilenumber: '',
    gender: '',
    dob: '',
    paddress: '',
    state: '',
    district: '',
    rto_number: '',
    zip: '',
    caste_cert: '',
    original_residence: '',
    pan_card: '',
    adhar_card: '',
    user_profile: '',
    latitude: '',
    longitude: ''
  });

  const validatePanCard = (pan) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(pan);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value
    });
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];

    // Check if the file type is PDF
    if (name === 'caste_cert' || name === 'original_residence') {
      if (file && file.type !== 'application/pdf') {
        handleShow([`${name} must be a PDF file.`], 'error');
        return;
      }
    }

    // Process the file
    if (file && file.type === 'application/pdf') {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        try {
          const pdfBuffer = fileReader.result;
          const pdfDoc = await PDFDocument.load(pdfBuffer);
          const pages = pdfDoc.getPages();

          const webpFiles = [];

          for (let i = 0; i < pages.length; i++) {
            const pdfPage = await pdfjsLib.getDocument({ data: pdfBuffer }).promise;
            const pageData = await pdfPage.getPage(i + 1);
            const viewport = pageData.getViewport({ scale: 2.0 });

            const canvas = document.createElement('canvas');
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            const context = canvas.getContext('2d');

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            await pageData.render(renderContext).promise;

            const webpDataUrl = await canvasToWebP(canvas);
            const webpBlob = dataURLtoBlob(webpDataUrl);
            const webpFile = new File([webpBlob], `page-${i + 1}.webp`, { type: 'image/webp' });

            webpFiles.push(webpFile);
          }

          setUserData({
            ...userData,
            [name]: webpFiles[0] // Assuming we are using only the first page's WEBP file
          });

          handlePdfPopup('PDF processed and scanned successfully.');
        } catch (error) {
          handlePdfPopup('Error processing PDF', 'error');
        }
      };

      fileReader.readAsArrayBuffer(file);
    } else {
      setUserData({
        ...userData,
        [name]: file
      });
    }
  };

  const canvasToWebP = (canvas) => {
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      }, 'image/webp');
    });
  };

  const dataURLtoBlob = (dataURL) => {
    const [header, data] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const binary = atob(data);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
  };


// Function to encrypt data
// Function to encrypt data


const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true); // Start loader

  // Check if PAN card format is valid
  if (!validatePanCard(userData.pan_card)) {
    handleShow(['Invalid PAN card format.'], 'error');
    setIsLoading(false);
    return;
  }

  const formData = new FormData();
  for (const key in userData) {
    formData.append(key, userData[key]);
  }

  // Add the captured image file to the form data
  if (userData.user_profile) {
    formData.append('user_profile', userData.user_profile, 'profile_image.webp');
  }

  try {
    const response = await createUser(formData);

    setDodigital_id(response.dodigital_id);
    setUserfname(response.first_name);
    setUserlname(response.last_name);
    setUserimage(response.user_profile);
    setUserid(response.user_id);

    
    if (response && response.dodigital_id) {
      setUserData((prevData) => ({
        ...prevData,
        dodigi_id: response.dodigital_id
      }));
      handleShow([`Registration successful! ${userData.fname} ${userData.lname}!`], 'success');
      resetForm();

      const secretKey = CryptoJS.enc.Utf8.parse('1234567890123456'); // Convert to WordArray
const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV

const encryptUserId = (userId) => {
  const encrypted = CryptoJS.AES.encrypt(userId.toString(), secretKey, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
};

const generateQRCodeURL = (encryptedId) => {
  // Use the original encodeURIComponent for better security
  const encodedId = encodeURIComponent(encryptedId);
  return `http://dodigitals.in/Verifications/${encodedId}?iv=${encodeURIComponent(iv.toString(CryptoJS.enc.Base64))}`;
};

// ... rest of your code

const encryptedId = encryptUserId(response.user_id);
const staticUrl = generateQRCodeURL(encryptedId);
setStaticUrl(staticUrl);
    } else {
      handleShow(['dodigi_id not found in response'], 'error');
    }
  } catch (error) {
    const errorMessages = [];
    
    if (error.response) {
      const { data } = error.response;

      if (data?.error?.email) {
        errorMessages.push('The email has already been taken.');
      }
      if (data?.error?.pan_card) {
        errorMessages.push('PAN card already exists.');
      }
      if (data?.error?.adhar_card) {
        errorMessages.push('Aadhar card already exists.');
      }
      if (data?.error?.caste_cert) {
        errorMessages.push('Caste Cert must be type: pdf.');
      }
      if (data?.error?.original_residence) {
        errorMessages.push('Original Residence must be type: pdf.');
      }
      if (data?.error?.user_profile) {
        errorMessages.push('Profile must be required');
      }
    }

    handleShow(errorMessages.length > 0 ? errorMessages : ['An unknown error occurred.'], 'error');
  } finally {
    setIsLoading(false);
  }
};



  

  
  const casteCertRef = useRef(null);
  const originalResidenceRef = useRef(null);
  const resetForm = () => {
    setUserData({
      username:'',
      fname: '',
      lname: '',
      email: '',
      mobilenumber: '',
      gender: '',
      dob: '',
      paddress: '',
      state: '',
      district: '',
      rto_number: '',
      zip: '',
      caste_cert: '',
      original_residence: '',
      pan_card: '',
      adhar_card: '',
      user_profile: '',
      latitude: '',
      longitude: ''
    });
    if (casteCertRef.current) {
      casteCertRef.current.value = '';
    }
    if (originalResidenceRef.current) {
      originalResidenceRef.current.value = '';
    }
    setIsCameraOpen(false);
    setImageSrc('');
    setTimer(null);
  };


  // Define image URLs and color dark mode and light mode change image start funtion
  const lightImageURL = whiteimg;
  const darkImageURL = Darkimg;

  const lightBgURL = whitebg;
  const darkBgURL = Darkbg;

  const [colorScheme, setColorScheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    const element = document.querySelector(".ban-short-links");
    if (element) {
      element.classList.add("ani");
    }
  }, []);
  // Define image URLs and color dark mode and light mode change image end funtion


  //  gradient color Start
  const styles = {
    background: colorScheme === 'dark'
      ? `linear-gradient(90deg, ${colors.theme_black_color}, ${colors.theme_white_color} 95%)`
      : `linear-gradient(90deg, ${colors.theme_white_color}, ${colors.theme_black_color} 95%)`,
  };

  // gradient color End

  // isFocused input style start function
  const [error, setError] = useState(''); // Add error state
  const [permissionDenied, setPermissionDenied] = useState(false); // Add permissionDenied state
  const [focusedInput, setFocusedInput] = useState(null);

  const inputStyles = (isFocused) => ({
    borderColor: isFocused
      ? `${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color
      }`
      : '#000',
    boxShadow: isFocused
      ? `none`
      : 'none',
    transition: 'border-color 0.3s', // Add more transitions as needed
  });
  // isFocused input style end function

  // profile image upload function Start
  const [imageSrc, setImageSrc] = useState('');
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [timer, setTimer] = useState(null);
  const [borderColor, setBorderColor] = useState('black');

  const openCamera = () => {
    if (!isCameraOpen) {
      setIsCameraOpen(true);
      setBorderColor('#ff702e');
      navigator.mediaDevices.getUserMedia({ video: true })
        .then((stream) => {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
          startTimer();
        })
        .catch((err) => {
          // console.error("Error accessing the camera: ", err);
        });
    }
  };

  const startTimer = () => {
    let count = 3;
    setTimer(count);
    const interval = setInterval(() => {
      count -= 1;
      setTimer(count);
      if (count === 0) {
        clearInterval(interval);
        capturePhoto();
      }
    }, 1000);
  };

  const capturePhoto = () => {
    const context = canvasRef.current.getContext('2d');
    context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
    const imageDataUrl = canvasRef.current.toDataURL('image/webp');

    // Convert data URL to Blob
    fetch(imageDataUrl)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], "profile_image.webp", { type: "image/webp" });
        setUserData({
          ...userData,
          user_profile: file
        });
        setImageSrc(imageDataUrl);
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        setIsCameraOpen(false);
        setTimer(null);
        setBorderColor('#008800');
      });
  };
  // profile image upload function End


  // Location function start
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ lat: latitude, lng: longitude, name: '' });
          try {
            const address = await getGeocodeFromCoordinates(latitude, longitude);
            setLocation((prevState) => ({ ...prevState, name: address }));
          } catch (error) {
            setError('Error fetching address from coordinates');
          }
        },
        (error) => {
          // console.error("Error obtaining location", error);
          setError('Error obtaining location');
          if (error.code === error.PERMISSION_DENIED) {
            setPermissionDenied(true);
          }
        }
      );
    } else {
      // console.error("Geolocation not available");
      setError('Geolocation not available');
    }
  }, []);

  // Check if the zip code has 6 digits and fetch the corresponding location
  useEffect(() => {
    if (userData.zip.length === 6) { // Assuming pincode length is 6
      handlePincodeSubmit();
    }
  }, [userData.zip]);

  // Fetch the latitude and longitude based on the zip code
  const handlePincodeSubmit = async () => {
    try {
      const { lat, lng, address } = await getGeocodeFromPincode(userData.zip);
      setUserData({
        ...userData,
        latitude: lat,
        longitude: lng
      });
      setLocation({ lat, lng, name: address });
    } catch (error) {
      // console.error("Error fetching location for pincode", error);
      setError('Error fetching location for pincode');
    }
  };
  // Location function end

  const handleDistrictChange = (districtId) => {
    const selectedDistrict = districts.find(district => district.id === parseInt(districtId));
    if (selectedDistrict) {
      setRtoNumbers(selectedDistrict.rto_codes || []);
    } else {
      setRtoNumbers([]);
    }
    setUserData({
      ...userData,
      district: districtId,
      rto_number: ''
    });
  };

  const extractRtoNumber = (rtoNumber) => {
    return rtoNumber.split('-')[1];
  };


  // card style 
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .do-digital-card-data-user-name {
        position: relative;
      }
      .do-digital-card-data-user-name::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 70px;
        height: 4px;
        background: ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color};
        z-index: 1;
      }
    `;
    document.head.appendChild(style);

    // Clean up function to remove the dynamically added style tag
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // 
  const handleDownload = () => {
    const nodeIds = [
      'popup-content-front',
      'popup-content-horizontal-back',
      'popup-content-vertical-front',
      'popup-content-vertical-back',
    ];
  
    const nodes = nodeIds.map(id => document.getElementById(id));
  
    // Filter out null elements (which aren't in the current view)
    const filteredNodes = nodes.filter(node => node !== null);
  
    const generatePDF = async () => {
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const topMargin = 20; // Top margin in mm
      let yOffset = topMargin;
  
      for (const node of filteredNodes) {
        const canvas = await html2canvas(node);
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 150; // Desired image width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        // Calculate x position to center the image
        const xOffset = (pageWidth - imgWidth) / 2;
  
        if (yOffset + imgHeight > pageHeight) {
          pdf.addPage();
          yOffset = topMargin; // Reset yOffset to top margin for new page
        }
  
        pdf.addImage(imgData, 'PNG', xOffset, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight;
      }
  
      pdf.save('Do-Digitals-Card.pdf');
    };
  
    generatePDF().catch((error) => {
      console.error('Oops, something went wrong!', error);
    });
  };



  const renderView = (view) => {
    switch (view) {
      case 'front':
        return (
          <div id="popup-content-front">
            <div className="do-digital-back-img">
              <img src={colorScheme === 'light' ? whitedesktopimg1URL : darkdesktopimg1URL} alt="sharp" />
            </div>
            <Row className="do-digital-card-data">
              <Col sm={5}>
                <div className="do-digital-card-data-user-profile">
                  <img
                    src={`https://dodigitals.in/indiancitizencard/public/${user_image}`}
                    alt="Do Digitals"
                    style={{ border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}` }}
                  />
                </div>
              </Col>
              <Col sm={7}>
                <div className="do-digital-card-data-user-name">
                  <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                    <span></span>{user_fname} {user_lname}
                  </p>
                </div>
                <div className="do-digital-card-data-user-id-number">
                  <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                    <strong>ID NO.:</strong> {dodigital_id}
                  </p>
                </div>
                <div className="do-digital-card-data-site">
                  <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                    https://dodigitals.in
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        );
      case 'back':
        return (
          <div className="do-digital-frient" id="popup-content-horizontal-back">
            <div className="do-digital-back-img">
              <img src={colorScheme === 'light' ? whitedesktopimg2URL : darkdesktopimg2URL} alt="sharp" />
            </div>
            <div className="do-digital-back-side-qr">
              {staticUrl && (
                <QRCode
                  value={staticUrl}
                  style={{ border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}` }}
                  size={256}
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="H"
                  includeMargin={true}
                  renderAs="svg"
                  imageSettings={{
                    src: 'https://dodigitals.in/dodigitlas.png',
                    x: null,
                    y: null,
                    height: 40,
                    width: 40,
                    excavate: true,
                  }}
                />
              )}
            </div>
          </div>
        );
      case 'vertical-front':
        return (
          <div className="do-digital-frient" id="popup-content-vertical-front">
            <div className="do-digital-back-img">
              <img src={colorScheme === 'light' ? whitemobileimg1URL : darkmobileimg1URL} alt="sharp" />
            </div>
            <div className="do-digital-card-data">
              <div className="do-digital-card-data-user-profile">
                <img
                  src={`https://dodigitals.in/indiancitizencard/public/${user_image}`}
                  alt="Do Digitals"
                  style={{ border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}` }}
                />
              </div>
              <div className="do-digital-card-data-user-name">
                <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                  <span></span>{user_fname} {user_lname}
                </p>
              </div>
              <div className="do-digital-card-data-user-id-number">
                <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                  <strong>ID NO.:</strong> {dodigital_id}
                </p>
              </div>
              <div className="do-digital-card-data-site">
                <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                  https://dodigitals.in
                </p>
              </div>
            </div>
          </div>
        );
      case 'vertical-back':
        return (
          <div className="do-digital-frient" id="popup-content-vertical-back">
            <div className="do-digital-back-img">
              <img src={colorScheme === 'light' ? whitemobileimg2URL : darkmobileimg2URL} alt="sharp" />
            </div>
            <div className="do-digital-back-side-qr">
              {staticUrl && (
                <QRCode
                  value={staticUrl}
                  style={{ border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}` }}
                  size={256}
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="H"
                  includeMargin={true}
                  renderAs="svg"
                  imageSettings={{
                    src: 'https://dodigitals.in/dodigitlas.png',
                    x: null,
                    y: null,
                    height: 40,
                    width: 40,
                    excavate: true,
                  }}
                />
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  
  // language Translate function start
  const { language } = useContext(LanguageContext);

  return (
    <div className='Do-digital'>
      {isLoading && <Loader />}
      <section>
        <div className="str ind2-home">
          <div className="hom-head" style={{ backgroundImage: `url(${colorScheme === 'light' ? lightBgURL : darkBgURL})` }}>
            <Header />
            <Container>
              <Row>
                <div className="ban-tit">
                  <h1>
                    <b style={{ textAlign: colorScheme === 'dark' ? 'center' : 'left' }}>{translate('do_digitals', language)} <span style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}> {translate('indiancitizencard', language)}
                      <img src={colorScheme === 'light' ? lightImageURL : darkImageURL} alt="Do-Digital" />
                    </span>
                    </b>
                    <br></br>
                    <span style={{ lineHeight: '32px', maxWidth: colorScheme === 'dark' ? '100%' : '600px', width: '100%', display: 'block', margin: '0 auto 0 0' }}>
                    {translate('creactdodigitalcard_page_description', language)}
                    </span>
                  </h1>
                </div>
              </Row>
            </Container>
          </div>
        </div>
      </section>
      <section className="mt-0 mb-60">
        <Container>
          <Row>
            <Col sm={7}>
              <div className="login-main">
                <div style={styles} className="log-bor">&nbsp;</div>
                <span style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }} className="steps">{translate('do_digitals', language)} {translate('indiancitizencard', language)}</span>
                <div className="log log-1">
                  <div className="login login-new">
                    <p>
                    {translate('creactdodigitalcard', language)}
                    </p>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="formGridFirstname" className="profile-image mb-5">
                        <div
                          className="mt-2"
                          style={{
                            maxWidth: '200px',
                            width: '100%',
                            height: '250px',
                            margin: 'auto',
                            cursor: 'pointer',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            border: `2px solid ${borderColor}`,
                          }}
                          onClick={openCamera}
                        >
                          {!isCameraOpen && (
                            <img
                              src={imageSrc || Profileimage}
                              alt="Captured"
                              style={{ maxWidth: '200px', maxHeight: '250px' }}
                              name="user_profile"
                              onChange={handleFileChange}
                            />
                          )}
                          {isCameraOpen && (
                            <video
                              ref={videoRef}
                              style={{ width: '200px', borderRadius: '8px', height: '250px' }}
                              name="user_profile"
                              onChange={handleFileChange}
                            ></video>
                          )}
                        </div>
                        {isCameraOpen && (
                          <div style={{ textAlign: 'center', marginTop: '10px' }} name="user_profile" onChange={handleFileChange}>
                            {timer !== null && (
                              <p style={{ fontSize: '2em', color: '#ff702e' }}>{timer > 0 ? timer : 'Click!'}</p>
                            )}
                          </div>
                        )}
                        <Form.Label>{translate('profile_image', language)}</Form.Label>
                        <canvas ref={canvasRef} style={{ display: 'none' }} width="200" height="250" name="user_profile" onChange={handleFileChange}></canvas>
                      </Form.Group>

                      <Row>
                        <Form.Group className="mb-3" as={Col} sm={6} xs={12} controlId="formGridFirstName">
                          <Form.Label>{translate('first_name', language)}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={`${translate('enter_your', language)} ${translate('first_name', language)} ${translate('enter_your1', language)}`} 
                            name="fname"
                            value={userData.fname}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'fname')}
                            onFocus={() => setFocusedInput('fname')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" as={Col} sm={6} xs={12} controlId="formGridLastName">
                          <Form.Label>{translate('last_name', language)}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={`${translate('enter_your', language)} ${translate('last_name', language)} ${translate('enter_your1', language)}`} 
                            name="lname"
                            value={userData.lname}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'lname')}
                            onFocus={() => setFocusedInput('lname')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                      <Form.Group className="mb-3" controlId="formGridLastName">
                          <Form.Label>{translate('username', language)}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={`${translate('enter_your', language)} ${translate('username', language)} ${translate('enter_your1', language)}`} 
                            name="username"
                            value={userData.username}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'username')}
                            onFocus={() => setFocusedInput('username')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>
                        </Row>
                      <Row>
                        <Form.Group className="mb-3" as={Col} sm={6} xs={12} controlId="formGridEmail">
                          <Form.Label>{translate('email', language)}</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder={`${translate('enter_your', language)} ${translate('email', language)} ${translate('enter_your1', language)}`} 
                            name="email"
                            value={userData.email}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'email')}
                            onFocus={() => setFocusedInput('email')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" as={Col} sm={6} xs={12} controlId="formGridMobileNumber">
                          <Form.Label>{translate('mobile_number', language)}</Form.Label>
                          <Form.Control
                            type="tel"
                            placeholder={`${translate('enter_your', language)} ${translate('mobile_number', language)} ${translate('enter_your1', language)}`} 
                            name="mobilenumber"
                            value={userData.mobilenumber}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'mobilenumber')}
                            onFocus={() => setFocusedInput('mobilenumber')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>
                      </Row>

                      <Row>
                        <Form.Group className="mb-3" as={Col} sm={6} xs={12} controlId="formGridGender">
                          <Form.Label>{translate('gender', language)}</Form.Label>
                          <Form.Control
                            as="select"
                            name="gender"
                            value={userData.gender}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'gender')}
                            onFocus={() => setFocusedInput('gender')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          >
                            <option value="">{translate('please_select1', language)} {translate('gender', language)} {translate('please_select2', language)}</option>
                            <option value="Male">{translate('male', language)}</option>
                            <option value="Female">{translate('female', language)}</option>
                            <option value="Other">{translate('अन्य', language)}</option>
                          </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" as={Col} sm={6} xs={12} controlId="formGridDOB">
                          <Form.Label>{translate('date_of_birth', language)}</Form.Label>
                          <Form.Control
                            type="date"
                            name="dob"
                            value={userData.dob}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'dob')}
                            onFocus={() => setFocusedInput('dob')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>
                      </Row>

                      <Form.Group className="mb-3" controlId="formGridAddress">
                        <Form.Label>{translate('permanent_address', language)}</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder={`${translate('enter_your', language)} ${translate('profile_image', language)} ${translate('enter_your1', language)}`} 
                          name="paddress"
                          value={userData.paddress}
                          onChange={handleChange}
                          style={inputStyles(focusedInput === 'paddress')}
                          onFocus={() => setFocusedInput('paddress')}
                          onBlur={() => setFocusedInput(null)}
                          required
                        />
                      </Form.Group>

                      <Row>
                        <Form.Group className="mb-3" as={Col} sm={6} xs={12} controlId="formGridState">
                          <Form.Label>{translate('state', language)}</Form.Label>
                          <Form.Control
                            as="select"
                            name="state"
                            value={userData.state}
                            onChange={(e) => {
                              const selectedAbbreviation = e.target.value;
                              const selectedState = states.find((state) => state.abbreviation === selectedAbbreviation);
                              handleChange(e);
                              handleStateChange(selectedState.id);
                            }}
                            style={inputStyles(focusedInput === 'state')}
                            onFocus={() => setFocusedInput('state')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          >
                            <option value="">{translate('please_select1', language)} {translate('state', language)} {translate('please_select2', language)}</option>
                            {states.map((state) => (
                              <option key={state.id} value={state.abbreviation}>
                                {state.name} ({state.abbreviation})
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group className="mb-3" as={Col} sm={6} xs={12} controlId="formGridDistrict">
                          <Form.Label>{translate('district', language)}</Form.Label>
                          <Form.Control
                            as="select"
                            name="district"
                            value={userData.district}
                            onChange={(e) => handleDistrictChange(e.target.value)}
                            style={inputStyles(focusedInput === 'district')}
                            onFocus={() => setFocusedInput('district')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          >
                            <option value="">{translate('please_select1', language)} {translate('district', language)} {translate('please_select2', language)}</option>
                            {districts.map((district) => (
                              <option key={district.id} value={district.id}>
                                {district.name}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="mb-3" as={Col} sm={6} xs={12} controlId="formGridRtoNumber">
                          <Form.Label>{translate('rto_number', language)}</Form.Label>
                          <Form.Control
                            as="select"
                            name="rto_number"
                            value={userData.rto_number}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'rto_number')}
                            onFocus={() => setFocusedInput('rto_number')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          >
                            <option value="">{translate('please_select1', language)} {translate('rto_number', language)} {translate('please_select2', language)}</option>
                            {rtoNumbers.map((rto) => (
                              <option key={rto.id} value={extractRtoNumber(rto.rto_number)}>
                                {rto.rto_number} ({rto.location})
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridZip">
                          <Form.Label>{translate('zip', language)}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={`${translate('enter_your', language)} ${translate('zip', language)} ${translate('enter_your1', language)}`} 
                            name="zip"
                            value={userData.zip}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'zip')}
                            onFocus={() => setFocusedInput('zip')}
                            onBlur={() => setFocusedInput(null)}
                            required />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridCasteCert">
                          <Form.Label>{translate('caste_certificate', language)}</Form.Label>
                          <Form.Control
                            type="file"
                            name="caste_cert"
                            onChange={handleFileChange}
                            required
                            ref={casteCertRef} />
                        </Form.Group>

                        <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridCasteCert">
                          <Form.Label>{translate('original_residence', language)}</Form.Label>
                          <Form.Control
                            type="file"
                            name="original_residence"
                            onChange={handleFileChange}
                            required
                            ref={originalResidenceRef} />
                        </Form.Group>
                      </Row>
                      <Form.Group className="mb-3" controlId="formGridPanCard">
                        <Form.Label>{translate('pan_card', language)}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={`${translate('enter_your', language)} ${translate('pan_card', language)} ${translate('enter_your1', language)}`} 
                          name="pan_card"
                          value={userData.pan_card}
                          onChange={handleChange}
                          style={inputStyles(focusedInput === 'pan_card')}
                          onFocus={() => setFocusedInput('pan_card')}
                          onBlur={() => setFocusedInput(null)}
                          required />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formGridAdharCard">
                        <Form.Label>{translate('aadhar_card', language)}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={`${translate('enter_your', language)} ${translate('aadhar_card', language)} ${translate('enter_your1', language)}`} 
                          name="adhar_card"
                          value={userData.adhar_card}
                          onChange={handleChange}
                          style={inputStyles(focusedInput === 'adhar_card')}
                          onFocus={() => setFocusedInput('adhar_card')}
                          onBlur={() => setFocusedInput(null)}
                          required
                        />
                      </Form.Group>
                      <Row>
                        <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridLatitude">
                          <Form.Label>{translate('latitude', language)}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={`${translate('enter_your', language)} ${translate('latitude', language)} ${translate('enter_your1', language)}`} 
                            name="latitude"
                            value={userData.latitude}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'latitude')}
                            onFocus={() => setFocusedInput('latitude')}
                            onBlur={() => setFocusedInput(null)}
                            required
                            readOnly
                          />
                        </Form.Group>

                        <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridLongitude">
                          <Form.Label>{translate('longitude', language)}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={`${translate('enter_your', language)} ${translate('longitude', language)} ${translate('enter_your1', language)}`} 
                            name="longitude"
                            value={userData.longitude}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'longitude')}
                            onFocus={() => setFocusedInput('longitude')}
                            onBlur={() => setFocusedInput(null)}
                            required
                            readOnly
                          />
                        </Form.Group>
                      </Row>

                      <Button variant="primary" type="submit" style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                      {translate('submit', language)}
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={5}>
              <div style={{ position: 'sticky', top: '100px' }}>
              <img src={colorScheme === 'light' ? lightcardImageURL : darkcardImageURL} alt="Do-Digital" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />

      {/* First Popup */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className={`error_massage_header ${messageInfo.type}`}>
            {messageInfo.type === 'error' ? (
              <Imagegif text={'error'} />
            ) : (
              <Imagegif text={'successfully'} />
            )}
            {show && (
              <ul className={`error_massage_contet ${messageInfo.type}`}>
                {messageInfo.message.map((msg, index) => (
                  <li key={index} className={`${messageInfo.type}_message`}>
                    {messageInfo.type === 'error' ? (
                      <span className="error_icon"></span>
                    ) : (
                      <div className="success_icon">
                        <h1>Thank You!</h1>
                      </div>
                    )}
                    {msg}
                  </li>
                ))}
              </ul>
            )}
          </Modal.Title>
        </Modal.Header>
      </Modal>

      {/* Second Popup */}
      <Modal
        show={showSecondPopup}
        onHide={() => setShowSecondPopup(false)}
        backdrop="static"
        keyboard={false}
        centered
        size="lg" className="popup-do-digital-card desktop-and-mobile-view-card-creat-style"
      >
        <Modal.Header closeButton>
          <Modal.Title className="do-digital-id-card-download">
            {renderView(view)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="desktop-and-mobile-view-card-creat-style-footer do-digital-id-card-footer">
        {/* <Button onClick={handleDownload} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>Download</Button> */}
          <Button onClick={() => setView('front')} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>Horizontal Front</Button>
          <Button onClick={() => setView('back')} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>Horizontal Back</Button>
          <Button onClick={() => setView('vertical-front')} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>Vertical Front</Button>
          <Button onClick={() => setView('vertical-back')} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>Vertical Back</Button>
        </Modal.Footer>
      </Modal>
      {/* PDF Processing Popup */}
      <Modal
        show={showPdfPopup}
        onHide={() => setShowPdfPopup(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className={`error_massage_header ${messageInfo.type}`}>
            {messageInfo.type === 'error' ? (
              <Imagegif text={'error'} />
            ) : (
              <Imagegif text={'successfully'} />
            )}
            {show && (
              <ul className={`error_massage_contet ${messageInfo.type}`}>
                {messageInfo.message.map((msg, index) => (
                  <li key={index} className={`${messageInfo.type}_message`}>
                    {messageInfo.type === 'error' ? (
                      <span className="error_icon"></span>
                    ) : (
                      <div className="success_icon">
                        <h1>Thank You!</h1>
                      </div>
                    )}
                    {msg}
                  </li>
                ))}
              </ul>
            )}
          </Modal.Title>
        </Modal.Header>
      </Modal>

    </div>
  )
}

export default Citizencard