import React, { useEffect, useState, useRef, useContext } from "react";
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { createProfessionCard } from '../components/Api';
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import * as colors from "../assets/css/Colors";
// Loader
import Loader from '../components/Loader';

import Darkimg from '../assets/img/curve2.png';
import whiteimg from '../assets/img/curve1.png';
import Darkbg from '../assets/img/bg-1.webp';
import whitebg from '../assets/img/bg-1-light.webp';
import Darkcardimg from '../assets/img/server/do-gif-orange.gif';
import whitecardimg from '../assets/img/server/do-gif-green.gif';
import Imagegif from "../components/Image_gif";

import darkdesktopimg1 from '../assets/img/do-digital-card/Do-Digital-Orange-Desktop-1.webp';
import darkdesktopimg2 from '../assets/img/do-digital-card/Do-DIgital-Orange-Desktop-2.webp';
import darkmobileimg1 from '../assets/img/do-digital-card/Do-Digital-Orange-Mobile-1.webp';
import darkmobileimg2 from '../assets/img/do-digital-card/Do-Digital-Orange-Mobile-2.webp';
import whitedesktopimg1 from '../assets/img/do-digital-card/Do-Digital-Green-Desktop-1.webp';
import whitedesktopimg2 from '../assets/img/do-digital-card/Do-Digital-Green-Desktop-2.webp';
import whitemobileimg1 from '../assets/img/do-digital-card/Do-Digital-Green-Mobile-1.webp';
import whitemobileimg2 from '../assets/img/do-digital-card/Do-Digital-Green-Mobile-2.webp';

import { PDFDocument } from 'pdf-lib';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import 'pdfjs-dist/web/pdf_viewer.css';
import jsPDF from 'jspdf';

// language
import { LanguageContext } from '../detectlanguage/LanguageContext';
import translate from "../detectlanguage/translate";

// qr code
import QRCode from 'qrcode.react';
import CryptoJS from 'crypto-js';
// import crypto from 'crypto';

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const CreateProfessionCardPage = () => {

  const darkdesktopimg1URL = darkdesktopimg1;
  const darkdesktopimg2URL = darkdesktopimg2;
  const darkmobileimg1URL = darkmobileimg1;
  const darkmobileimg2URL = darkmobileimg2;
  const whitedesktopimg1URL = whitedesktopimg1;
  const whitedesktopimg2URL = whitedesktopimg2;
  const whitemobileimg1URL = whitemobileimg1;
  const whitemobileimg2URL = whitemobileimg2;


  const lightcardImageURL = whitecardimg;
  const darkcardImageURL = Darkcardimg;

  // erroe massage open popup 

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageInfo, setMessageInfo] = useState({ message: [], type: '' });
  const [showSecondPopup, setShowSecondPopup] = useState(false);
  const [showPdfPopup, setShowPdfPopup] = useState(false);
  const [pdfMessage, setPdfMessage] = useState('');
  const [view, setView] = useState('vertical-front');
  const [rtoNumbers, setRtoNumbers] = useState([]);
  const fileInputRef = useRef(null);


  const handleShow = (message, type = 'error', show = true) => {
    setMessageInfo({ message, type });
    setShow(show);
    if (type === 'success') {
      setTimeout(() => {
        setShow(false);
        setShowSecondPopup(true);
      }, 5000); // Auto close after 5 seconds
    }
  };


  const handlePdfPopup = (message, type = 'success') => {
    setMessageInfo({ message: [message], type });
    setShow(true);
    setShowPdfPopup(true);
    setTimeout(() => {
      setShow(false);
      setShowPdfPopup(false);
    }, 3000); // Auto close after 5 seconds
  };

  const [dodigital_id, setDodigital_id] = useState('');
  const [user_fname, setUserfname] = useState('');
  const [user_lname, setUserlname] = useState('');
  const [user_image, setUserimage] = useState();
  const [user_id, setUserid] = useState();
  const [staticUrl, setStaticUrl] = useState('');
  const [formData, setFormData] = useState({
    dodigi_id: '',
    user_type: '',
    username: '',
    email: '',
    mobilenumber: '',
    pan_card: '',
    adhar_card: '',
    degree_certificate: null,
    experience_letter_or_concent_form: null,
    internship_certificate: null,
    reference_letter: null,
    salary_slip: null,
    training_certificate: null,
  });
  const validatePanCard = (pan) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    return panRegex.test(pan);
  };







  const [error, setError] = useState(''); // Add error state
  const [successMessage, setSuccessMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

 







  const handleFileChange = async (e) => {
    const { name } = e.target;
    const file = e.target.files[0];
    setFormData({
      ...formData,
      [name]: file,
    });

    // Check if the file type is PDF
    if (name === 'caste_cert' || name === 'original_residence') {
      if (file && file.type !== 'application/pdf') {
        handleShow([`${name} must be a PDF file.`], 'error');
        return;
      }
    }

    // Process the file
    if (file && file.type === 'application/pdf') {
      const fileReader = new FileReader();
      fileReader.onload = async () => {
        try {
          const pdfBuffer = fileReader.result;
          const pdfDoc = await PDFDocument.load(pdfBuffer);
          const pages = pdfDoc.getPages();

          const webpFiles = [];

          for (let i = 0; i < pages.length; i++) {
            const pdfPage = await pdfjsLib.getDocument({ data: pdfBuffer }).promise;
            const pageData = await pdfPage.getPage(i + 1);
            const viewport = pageData.getViewport({ scale: 2.0 });

            const canvas = document.createElement('canvas');
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            const context = canvas.getContext('2d');

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            await pageData.render(renderContext).promise;

            const webpDataUrl = await canvasToWebP(canvas);
            const webpBlob = dataURLtoBlob(webpDataUrl);
            const webpFile = new File([webpBlob], `page-${i + 1}.webp`, { type: 'image/webp' });

            webpFiles.push(webpFile);
          }

          setFormData({
            ...formData,
            [name]: webpFiles[0] // Assuming we are using only the first page's WEBP file
          });

          handlePdfPopup('PDF processed and scanned successfully.');
        } catch (error) {
          handlePdfPopup('Error processing PDF', 'error');
        }
      };

      fileReader.readAsArrayBuffer(file);
    } else {
      setFormData({
        ...formData,
        [name]: file
      });
    }
  };

  const canvasToWebP = (canvas) => {
    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      }, 'image/webp');
    });
  };

  const dataURLtoBlob = (dataURL) => {
    const [header, data] = dataURL.split(',');
    const mime = header.match(/:(.*?);/)[1];
    const binary = atob(data);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
  };




  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);
    setIsLoading(true); // Start loader
  
    // Check if PAN card format is valid
    if (!validatePanCard(formData.pan_card)) {
      handleShow(['Invalid PAN card format.'], 'error');
      setIsLoading(false);
      return;
    }
  
    const formDataToSend = new FormData();
    Object.keys(formData).forEach((key) => {
      if (formData[key] instanceof File) {
        formDataToSend.append(key, formData[key]);
      } else {
        formDataToSend.append(key, formData[key]);
      }
    });
  
    try {
      const response = await createProfessionCard(formDataToSend);
  
      if (response && response.professioncard_id) {
        setDodigital_id(response.professioncard_id);
        setUserfname(response.first_name);
        setUserlname(response.last_name);
        setUserimage(response.user_profile);
        setUserid(response.user_id);
  
        setFormData((prevData) => ({
          ...prevData,
          professioncard_id: response.professioncard_id
        }));
  
        handleShow([`Registration successful! ${formData.username}!`], 'success');
        resetForm();
  
        const secretKey = CryptoJS.enc.Utf8.parse('1234567890123456'); // Convert to WordArray
        const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
  
        const encryptUserId = (userId) => {
          const encrypted = CryptoJS.AES.encrypt(userId.toString(), secretKey, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          });
          return encrypted.toString();
        };
  
        const generateQRCodeURL = (encryptedId) => {
          const encodedId = encodeURIComponent(encryptedId);
          return `http://dodigitals.in/Verifications/${encodedId}?iv=${encodeURIComponent(iv.toString(CryptoJS.enc.Base64))}`;
        };
  
        const encryptedId = encryptUserId(response.user_id);
        const staticUrl = generateQRCodeURL(encryptedId);
        setStaticUrl(staticUrl);
      } else {
        handleShow(['professioncard_id not found in response'], 'error');
      }
    } catch (error) {
      console.error('Error during form submission:', error);
  
      // Extract and display specific error messages
      const errorMessage = error.message || 'An unexpected error occurred.';
      handleShow([errorMessage], 'error');
    } finally {
      setIsLoading(false); // Stop loader
    }
  };
  

  const degreecertificateRef = useRef(null);
  const experienceletterorconcentformRef = useRef(null);
  const internshipcertificateRef = useRef(null);
  const referenceletterRef = useRef(null);
  const salaryslipRef = useRef(null);
  const trainingcertificateRef = useRef(null);
  const resetForm = () => {
    setFormData({
      dodigi_id: '',
    user_type: '',
    username: '',
    email: '',
    mobilenumber: '',
    pan_card: '',
    adhar_card: '',
    });
    if (degreecertificateRef.current) {
      degreecertificateRef.current.value = '';
    }
    if (experienceletterorconcentformRef.current) {
      experienceletterorconcentformRef.current.value = '';
    }
    if (internshipcertificateRef.current) {
      internshipcertificateRef.current.value = '';
    }
    if (referenceletterRef.current) {
      referenceletterRef.current.value = '';
    }
    if (salaryslipRef.current) {
      salaryslipRef.current.value = '';
    }
    if (trainingcertificateRef.current) {
      trainingcertificateRef.current.value = '';
    }
  };




  // other function
  // Define image URLs and color dark mode and light mode change image start funtion
  const lightImageURL = whiteimg;
  const darkImageURL = Darkimg;

  const lightBgURL = whitebg;
  const darkBgURL = Darkbg;

  const [colorScheme, setColorScheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    const handleChange = (e) => {
      setColorScheme(e.matches ? 'dark' : 'light');
    };

    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  useEffect(() => {
    const element = document.querySelector(".ban-short-links");
    if (element) {
      element.classList.add("ani");
    }
  }, []);
  // Define image URLs and color dark mode and light mode change image end funtion

  //  gradient color Start
  const styles = {
    background: colorScheme === 'dark'
      ? `linear-gradient(90deg, ${colors.theme_black_color}, ${colors.theme_white_color} 95%)`
      : `linear-gradient(90deg, ${colors.theme_white_color}, ${colors.theme_black_color} 95%)`,
  };

  // isFocused input style start function
  const [permissionDenied, setPermissionDenied] = useState(false); // Add permissionDenied state
  const [focusedInput, setFocusedInput] = useState(null);

  const inputStyles = (isFocused) => ({
    borderColor: isFocused
      ? `${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color
      }`
      : '#000',
    boxShadow: isFocused
      ? `none`
      : 'none',
    transition: 'border-color 0.3s', // Add more transitions as needed
  });
  // isFocused input style end function



   // card style 
   useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      .do-digital-card-data-user-name {
        position: relative;
      }
      .do-digital-card-data-user-name::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 70px;
        height: 4px;
        background: ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color};
        z-index: 1;
      }
    `;
    document.head.appendChild(style);

    // Clean up function to remove the dynamically added style tag
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  // 
  const handleDownload = () => {
    const nodeIds = [
      'popup-content-front',
      'popup-content-horizontal-back',
      'popup-content-vertical-front',
      'popup-content-vertical-back',
    ];
  
    const nodes = nodeIds.map(id => document.getElementById(id));
  
    // Filter out null elements (which aren't in the current view)
    const filteredNodes = nodes.filter(node => node !== null);
  
    const generatePDF = async () => {
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const topMargin = 20; // Top margin in mm
      let yOffset = topMargin;
  
      for (const node of filteredNodes) {
        const canvas = await html2canvas(node);
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 150; // Desired image width in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
  
        // Calculate x position to center the image
        const xOffset = (pageWidth - imgWidth) / 2;
  
        if (yOffset + imgHeight > pageHeight) {
          pdf.addPage();
          yOffset = topMargin; // Reset yOffset to top margin for new page
        }
  
        pdf.addImage(imgData, 'PNG', xOffset, yOffset, imgWidth, imgHeight);
        yOffset += imgHeight;
      }
  
      pdf.save('Do-Digitals-Card.pdf');
    };
  
    generatePDF().catch((error) => {
      console.error('Oops, something went wrong!', error);
    });
  };



  const renderView = (view) => {
    switch (view) {
      case 'front':
        return (
          <div id="popup-content-front">
            <div className="do-digital-back-img">
              <img src={colorScheme === 'light' ? whitedesktopimg1URL : darkdesktopimg1URL} alt="sharp" />
            </div>
            <Row className="do-digital-card-data">
              <Col sm={5}>
                <div className="do-digital-card-data-user-profile">
                  <img
                    src={`https://dodigitals.in/indiancitizencard/public/${user_image}`}
                    alt="Do Digitals"
                    style={{ border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}` }}
                  />
                </div>
              </Col>
              <Col sm={7}>
                <div className="do-digital-card-data-user-name">
                  <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                    <span></span>{user_fname} {user_lname}
                  </p>
                </div>
                <div className="do-digital-card-data-user-id-number">
                  <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                    <strong>ID NO.:</strong> {dodigital_id}
                  </p>
                </div>
                <div className="do-digital-card-data-site">
                  <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                    https://dodigitals.in
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        );
      case 'back':
        return (
          <div className="do-digital-frient" id="popup-content-horizontal-back">
            <div className="do-digital-back-img">
              <img src={colorScheme === 'light' ? whitedesktopimg2URL : darkdesktopimg2URL} alt="sharp" />
            </div>
            <div className="do-digital-back-side-qr">
              {staticUrl && (
                <QRCode
                  value={staticUrl}
                  style={{ border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}` }}
                  size={256}
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="H"
                  includeMargin={true}
                  renderAs="svg"
                  imageSettings={{
                    src: 'https://dodigitals.in/dodigitlas.png',
                    x: null,
                    y: null,
                    height: 40,
                    width: 40,
                    excavate: true,
                  }}
                />
              )}
            </div>
          </div>
        );
      case 'vertical-front':
        return (
          <div className="do-digital-frient" id="popup-content-vertical-front">
            <div className="do-digital-back-img">
              <img src={colorScheme === 'light' ? whitemobileimg1URL : darkmobileimg1URL} alt="sharp" />
            </div>
            <div className="do-digital-card-data">
              <div className="do-digital-card-data-user-profile">
                <img
                  src={`https://dodigitals.in/indiancitizencard/public/${user_image}`}
                  alt="Do Digitals"
                  style={{ border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}` }}
                />
              </div>
              <div className="do-digital-card-data-user-name">
                <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                  <span></span>{user_fname} {user_lname}
                </p>
              </div>
              <div className="do-digital-card-data-user-id-number">
                <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                  <strong>ID NO.:</strong> {dodigital_id}
                </p>
              </div>
              <div className="do-digital-card-data-site">
                <p style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                  https://dodigitals.in
                </p>
              </div>
            </div>
          </div>
        );
      case 'vertical-back':
        return (
          <div className="do-digital-frient" id="popup-content-vertical-back">
            <div className="do-digital-back-img">
              <img src={colorScheme === 'light' ? whitemobileimg2URL : darkmobileimg2URL} alt="sharp" />
            </div>
            <div className="do-digital-back-side-qr">
              {staticUrl && (
                <QRCode
                  value={staticUrl}
                  style={{ border: `2px solid ${colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color}` }}
                  size={256}
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="H"
                  includeMargin={true}
                  renderAs="svg"
                  imageSettings={{
                    src: 'https://dodigitals.in/dodigitlas.png',
                    x: null,
                    y: null,
                    height: 40,
                    width: 40,
                    excavate: true,
                  }}
                />
              )}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

    // language Translate function start
    const { language } = useContext(LanguageContext);

  return (
    <div className='Do-digital'>
      {isLoading && <Loader />}
      <section>
        <div className="str ind2-home">
          <div className="hom-head" style={{ backgroundImage: `url(${colorScheme === 'light' ? lightBgURL : darkBgURL})` }}>
            <Header />
            <Container>
              <Row>
                <div className="ban-tit">
                  <h1>
                    <b style={{ textAlign: colorScheme === 'dark' ? 'center' : 'left' }}>{translate('do_digitals', language)} <span style={{ color: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}> {translate('india_citizen_profession_card', language)}
                      <img src={colorScheme === 'light' ? lightImageURL : darkImageURL} alt="Do-Digital" />
                    </span>
                    </b>
                    <br></br>
                    <span style={{ lineHeight: '32px', maxWidth: colorScheme === 'dark' ? '100%' : '600px', width: '100%', display: 'block', margin: '0 auto 0 0' }}>
                    {translate('india_citizen_profession_card_description', language)}
                    </span>
                  </h1>
                </div>
              </Row>
            </Container>
          </div>
        </div>
      </section>

      <section className="mt-0 mb-60">
        <Container>
          <Row>
            <Col sm={7}>
              <div className="login-main">
                <div style={styles} className="log-bor">&nbsp;</div>
                <span style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }} className="steps">{translate('do_digitals', language)} {translate('india_citizen_profession_card', language)}</span>
                <div className="log log-1">
                  <div className="login login-new">
                    <p>
                    {translate('indiacitizenprofessioncard', language)}
                    </p>
                    {error && (
                      <div style={{ color: 'red' }}>
                        {error.general ? (
                          <p>{error.general}</p>
                        ) : (
                          Object.keys(error).map((key) => (
                            <div key={key}>
                              {error[key].map((message, index) => (
                                <p key={index}>{message}</p>
                              ))}
                            </div>
                          ))
                        )}
                      </div>
                    )}
                    {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
                    <Form onSubmit={handleSubmit}>
                      <Row>
                        <Form.Group className="mb-3" controlId="formGridDodigiid">
                          <Form.Label>{translate('do_digital_card_number', language)}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={`${translate('enter_your', language)} ${translate('do_digital_card_number', language)} ${translate('enter_your1', language)}`} 
                            name="dodigi_id"
                            value={formData.dodigi_id}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'dodigi_id')}
                            onFocus={() => setFocusedInput('dodigi_id')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>
                        </Row>
                        <Row>
                        <Form.Group className="mb-3" controlId="formGridUsertype">
                          <Form.Label>{translate('your_profession', language)}</Form.Label>
                          <Form.Control
                            as="select"
                            name="user_type"
                            value={formData.user_type}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'user_type')}
                            onFocus={() => setFocusedInput('user_type')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          >
                           <option value="">{translate('please_select1', language)} {translate('your_profession', language)} {translate('please_select2', language)}</option>
                            <option value="student">{translate('students', language)}</option>
                            <option value="teacher">{translate('teachers', language)}</option>
                          </Form.Control>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="mb-3" controlId="formGridUsername">
                          <Form.Label>{translate('username', language)}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={`${translate('enter_your', language)} ${translate('username', language)} ${translate('enter_your1', language)}`} 
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'username')}
                            onFocus={() => setFocusedInput('username')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group className="mb-3" as={Col} sm={6} xs={12} controlId="formGridEmail">
                          <Form.Label>{translate('email', language)}</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder={`${translate('enter_your', language)} ${translate('email', language)} ${translate('enter_your1', language)}`} 
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'email')}
                            onFocus={() => setFocusedInput('email')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-3" as={Col} sm={6} xs={12} controlId="formGridMobileNumber">
                          <Form.Label>{translate('mobile_number', language)}</Form.Label>
                          <Form.Control
                            type="tel"
                            placeholder={`${translate('enter_your', language)} ${translate('mobile_number', language)} ${translate('enter_your1', language)}`} 
                            name="mobilenumber"
                            value={formData.mobilenumber}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'mobilenumber')}
                            onFocus={() => setFocusedInput('mobilenumber')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridPanCard">
                          <Form.Label>{translate('pan_card', language)}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={`${translate('enter_your', language)} ${translate('pan_card', language)} ${translate('enter_your1', language)}`} 
                            name="pan_card"
                            value={formData.pan_card}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'pan_card')}
                            onFocus={() => setFocusedInput('pan_card')}
                            onBlur={() => setFocusedInput(null)}
                            required />
                        </Form.Group>
                        <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridAdharCard">
                          <Form.Label>{translate('aadhar_card', language)}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={`${translate('enter_your', language)} ${translate('aadhar_card', language)} ${translate('enter_your1', language)}`} 
                            name="adhar_card"
                            value={formData.adhar_card}
                            onChange={handleChange}
                            style={inputStyles(focusedInput === 'adhar_card')}
                            onFocus={() => setFocusedInput('adhar_card')}
                            onBlur={() => setFocusedInput(null)}
                            required
                          />
                        </Form.Group>
                      </Row>
                      {formData.user_type === 'teacher' && (
                        <>

                          <Row>
                            <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridDegreecertificate">
                              <Form.Label> {translate('degree_certificate', language)}</Form.Label>
                              <Form.Control
                                type="file"
                                name="degree_certificate"
                                onChange={handleFileChange}
                                ref={degreecertificateRef} />
                            </Form.Group>

                            <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridExperienceletterorconcentform">
                              <Form.Label>{translate('experience_letter_or_consent_form', language)}</Form.Label>
                              <Form.Control
                                type="file"
                                name="experience_letter_or_concent_form"
                                onChange={handleFileChange}
                                ref={experienceletterorconcentformRef} />
                            </Form.Group>
                          </Row>

                          <Row>
                            <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridInternshipcertificate">
                              <Form.Label> {translate('internship_certificate', language)}</Form.Label>
                              <Form.Control
                                type="file"
                                name="internship_certificate"
                                onChange={handleFileChange}
                                ref={internshipcertificateRef} />
                            </Form.Group>

                            <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridReferenceletter">
                              <Form.Label> {translate('reference_letter', language)}</Form.Label>
                              <Form.Control
                                type="file"
                                name="reference_letter"
                                onChange={handleFileChange}
                                ref={referenceletterRef} />
                            </Form.Group>
                          </Row>

                          <Row>
                            <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridSalaryslip">
                              <Form.Label> {translate('salary_slip', language)}</Form.Label>
                              <Form.Control
                                type="file"
                                name="salary_slip"
                                onChange={handleFileChange}
                                ref={salaryslipRef} />
                            </Form.Group>

                            <Form.Group as={Col} sm={6} xs={12} className="mb-3" controlId="formGridTrainingcertificate">
                              <Form.Label> {translate('training_certificate', language)}</Form.Label>
                              <Form.Control
                                type="file"
                                name="training_certificate"
                                onChange={handleFileChange}
                                ref={trainingcertificateRef} />
                            </Form.Group>
                          </Row>
                        </>
                      )}
                       <Button variant="primary" type="submit" style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>
                       {translate('submit', language)}
                      </Button>
                    </Form>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={5}>
              <div style={{ position: 'sticky', top: '100px' }}>
                <img src={colorScheme === 'light' ? lightcardImageURL : darkcardImageURL} alt="Do-Digital" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>




      <Footer />

       {/* First Popup */}
       <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className={`error_massage_header ${messageInfo.type}`}>
            {messageInfo.type === 'error' ? (
              <Imagegif text={'error'} />
            ) : (
              <Imagegif text={'successfully'} />
            )}
            {show && (
              <ul className={`error_massage_contet ${messageInfo.type}`}>
                {messageInfo.message.map((msg, index) => (
                  <li key={index} className={`${messageInfo.type}_message`}>
                    {messageInfo.type === 'error' ? (
                      <span className="error_icon"></span>
                    ) : (
                      <div className="success_icon">
                        <h1>Thank You!</h1>
                      </div>
                    )}
                    {msg}
                  </li>
                ))}
              </ul>
            )}
          </Modal.Title>
        </Modal.Header>
      </Modal>

      {/* Second Popup */}
      <Modal
        show={showSecondPopup}
        onHide={() => setShowSecondPopup(false)}
        backdrop="static"
        keyboard={false}
        centered
        size="lg" className="popup-do-digital-card desktop-and-mobile-view-card-creat-style"
      >
        <Modal.Header closeButton>
          <Modal.Title className="do-digital-id-card-download">
            {renderView(view)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="desktop-and-mobile-view-card-creat-style-footer do-digital-id-card-footer">
          <Button onClick={handleDownload} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>Download</Button>
          <Button onClick={() => setView('front')} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>Horizontal Front</Button>
          <Button onClick={() => setView('back')} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>Horizontal Back</Button>
          <Button onClick={() => setView('vertical-front')} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>Vertical Front</Button>
          <Button onClick={() => setView('vertical-back')} style={{ backgroundColor: colorScheme === 'dark' ? colors.theme_black_color : colors.theme_white_color }}>Vertical Back</Button>
        </Modal.Footer>
      </Modal>
      {/* PDF Processing Popup */}
      <Modal
        show={showPdfPopup}
        onHide={() => setShowPdfPopup(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className={`error_massage_header ${messageInfo.type}`}>
            {messageInfo.type === 'error' ? (
              <Imagegif text={'error'} />
            ) : (
              <Imagegif text={'successfully'} />
            )}
            {show && (
              <ul className={`error_massage_contet ${messageInfo.type}`}>
                {messageInfo.message.map((msg, index) => (
                  <li key={index} className={`${messageInfo.type}_message`}>
                    {messageInfo.type === 'error' ? (
                      <span className="error_icon"></span>
                    ) : (
                      <div className="success_icon">
                        <h1>Thank You!</h1>
                      </div>
                    )}
                    {msg}
                  </li>
                ))}
              </ul>
            )}
          </Modal.Title>
        </Modal.Header>
      </Modal>
    </div>
  );
};

export default CreateProfessionCardPage;
