import React from 'react';

const Serverimage = ({ text }) => {
  return (
    <img
      src={`https://dodigitals.in/indiancitizencard/public/${text}`}
      alt='DoDigital'
      loading="lazy"
    />
  );
};

export default Serverimage;
